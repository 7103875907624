@use './vars' as *;

.retry__button {
  padding: 8px 16px;
  border: 2px solid $primary-color;
  cursor: pointer;
  background-color: #F06C00;
  font-size: 1.125rem;
  line-height: 1.33333;
  font-weight: 700;
  color: #fff;
  width: 170px;
  box-shadow: 0 0 0 3px transparent;
}

.retry__button:hover {
  background-color: #E55000;
  border: 2px solid #E55000;
  transition-duration: .2s;
  transition-property: box-shadow,background-color,border,color;
  transition-timing-function: ease-out;
}