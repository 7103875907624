.container {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.ccp {
    height: 100vh;
}

.sidebar {
    padding: 30px;
}