.cti__btn {
    background-color: #008CBA; /* Blue */
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px;
    border-radius: 5px;
}

.agentinfo__container > * {
    margin: 3px 0;
}

.agentinfo__label {
    font-family: MaryAnnBold;
}
.customerinfo_edit {
    float:right;
    cursor:pointer;
}
.customerinfo_modal_error {
  color:red;
  padding:15%;
}
.customerinfo_modal_close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin: -10px 0px 0px 0px;
  }
  .customerinfo_modal_close:hover,
  .customerinfo_modal_close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .customerinfo_modal_button {
    background-color: #008CBA;
    border: none;
    color: white;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    cursor: pointer;
    margin: 7% 0% 0% 33%;
  }
  .customerinfo_modal_varification{
    min-width:130px;
    text-align:center;
  }