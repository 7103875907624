fieldset {
    background-color: #eeeeee;
    height: auto;
    min-height: 20vh;
    border-radius: 5px;
}

legend {
    background-color: gray;
    color: white;
    padding: 5px 10px !important;
    font-family: MaryAnnExtraBold, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
