@use '../styles/vars' as *;

.loader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
}

@keyframes lineScale {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(1);
  }
}

.lineScale > div:nth-child(1) {
  animation: lineScale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div:nth-child(2) {
  animation: lineScale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div:nth-child(3) {
  animation: lineScale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div:nth-child(4) {
  animation: lineScale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div:nth-child(5) {
  animation: lineScale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.lineScale > div {
  background-color: $primary-color;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
}

@keyframes ball-grid-pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.5);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.ball-grid-pulse {
  width: 57px;
}

.ball-grid-pulse > div:nth-child(1) {
  animation-delay: 0.22s;
  animation-duration: 0.9s;
}

.ball-grid-pulse > div:nth-child(2) {
  animation-delay: 0.64s;
  animation-duration: 1s;
}

.ball-grid-pulse > div:nth-child(3) {
  animation-delay: -0.15s;
  animation-duration: 0.63s;
}

.ball-grid-pulse > div:nth-child(4) {
  animation-delay: -0.03s;
  animation-duration: 1.24s;
}

.ball-grid-pulse > div:nth-child(5) {
  animation-delay: 0.08s;
  animation-duration: 1.37s;
}

.ball-grid-pulse > div:nth-child(6) {
  animation-delay: 0.43s;
  animation-duration: 1.55s;
}

.ball-grid-pulse > div:nth-child(7) {
  animation-delay: 0.05s;
  animation-duration: 0.7s;
}

.ball-grid-pulse > div:nth-child(8) {
  animation-delay: 0.05s;
  animation-duration: 0.97s;
}

.ball-grid-pulse > div:nth-child(9) {
  animation-delay: 0.3s;
  animation-duration: 0.63s;
}

.ball-grid-pulse > div {
  background-color: $primary-color;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  float: left;
  animation-name: ball-grid-pulse;
  animation-iteration-count: infinite;
  animation-delay: 0;
}

@keyframes ball-scale {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.ball-scale > div {
  background-color: $primary-color;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
  height: 60px;
  width: 60px;
  animation: ball-scale 1s 0s ease-in-out infinite;
}

.text {
  font-size: 1.4rem;
  color: #37474f;
  padding-bottom: 16px;
}

.children {
  padding-top: 22px;
}
